import React from 'react';
import styled from 'styled-components';
import TemplatePage1 from '@components/TemplatePage/TemplatePage';


const IndexPage = () => {


  return (
    <TemplatePage1
      title={"Goal 1: Consolidate as the QUEST LAC Latin American Network"}
      showtitle={false}
      description={"Goal 1: Consolidate as the QUEST LAC Latin American Network"}
      bg={"var(--tema4)"}
      content={(
        <Wrapper>
          
        </Wrapper>
      )}
    />
  )
};

export default IndexPage

const Wrapper = styled.div`

`;